import TermAndCondition from "components/AuthPages/TermAndCondition";
import NoAuthRoute from "middleware/NoAuth";
import { Route, Routes } from "react-router-dom";
import Scan from "views/Scan";
import FourZeroFour from "./components/FourZeroFour";
import ScrollToTop from "./components/Helpers/ScrollToTop";
import MyCollection from "./components/MyCollection";
import Notification from "./components/Notification";
import AuthRoute from "./middleware/AuthRoute";
import AcitveBidsPage from "./views/AcitveBidsPage";
import AuthProfilePage from "./views/AuthProfilePage";
import CollectionItemPage from "./views/CollectionItemPage";
import HomePages from "./views/HomePages";
import InvestPage from "./views/InvestPage";
import MarketPlacePage from "./views/MarketPlacePage";
import MemberPage from "./views/MemberPage";
import MyWalletPage from "./views/MyWalletPage";
import SavedPage from "./views/SavedPage";
import SellPage from "./views/SellPage";
import SettingsPage from "./views/SettingsPage";
import ShopDetailsPage from "./views/ShopDetailsPage";
import TransactionPage from "./views/TransactionPage";
import UploadProductPage from "./views/UploadProductPage";
import UserProfilePage from "./views/UserProfilePage";
import ForgotPasswordPages from "./views/auth/ForgotPasswordPages";
import LoginPage from "./views/auth/LoginPage";
import SignupPage from "./views/auth/SignupPage";
import UpdatePasswordPages from "./views/auth/UpdatePasswordPages";
import VerifyYouPages from "./views/auth/VerifyYouPages";

export default function Routers() {
  return (
    <ScrollToTop>
      <Routes>
        <Route element={<NoAuthRoute />}>
          {/* guest routes */}
          <Route exact path="/login" element={<LoginPage />} />
          {/* <Route exact path="/signup" element={<SignupPage />} /> */}
          <Route
            exact
            path="/forgot-password"
            element={<ForgotPasswordPages />}
          />
          <Route
            exact
            path="/update-password"
            element={<UpdatePasswordPages />}
          />
          <Route exact path="/verify-you" element={<VerifyYouPages />} />
        </Route>
        <Route exact path="/scan" element={<Scan/>}/>
        <Route exact path="/term-and-condition" element={<TermAndCondition/>}/>

        {/* private route */}
        <Route element={<AuthRoute />}>
          <Route exact path="/active-bids" element={<AcitveBidsPage />} />
          <Route exact path="/notification" element={<Notification />} />
          <Route exact path="/market-place" element={<MarketPlacePage />} />
          <Route exact path="/shop-details" element={<ShopDetailsPage />} />
          <Route exact path="/my-wallet" element={<MyWalletPage />} />
          <Route exact path="/my-collection" element={<MyCollection />} />
          <Route
            exact
            path="/my-collection/collection-item"
            element={<CollectionItemPage />}
          />
          <Route exact path="/sell" element={<SellPage />} />
          <Route exact path="/saved" element={<SavedPage />} />
          <Route exact path="/upload-product" element={<UploadProductPage />} />
          <Route exact path="/profile" element={<AuthProfilePage />} />
          <Route exact path="/user-profile" element={<UserProfilePage />} />
          <Route exact path="/settings" element={<SettingsPage />} />
          <Route exact path="/invest" element={<InvestPage />} />
          <Route exact path="/member" element={<MemberPage />} />
          <Route exact path="/transactions" element={<TransactionPage />} />
          <Route exact path="/" element={<HomePages />} />
        </Route>

        <Route path="*" element={<FourZeroFour />} />
      </Routes>
    </ScrollToTop>
  );
}
